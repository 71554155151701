import React, { Suspense, lazy } from "react";

// Screens
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/searchbar.css";
import TopNavbar from "./components/Nav/TopNavbar.jsx";
import Footer from "./components/Sections/Footer";
import LoadingSpinner from "./components/Loader/LoadingSpinner.jsx";
import ScrollToTopButton from "./components/Buttons/ScrollToTopButton.jsx";
import DownloadApp from "./components/Sections/DownloadApp.jsx";
import ScrollToTop from "./hooks/useScrollTop.js";


const MapsPage = lazy(() => import("./screens/MapsPage.jsx"));
const PrivacyPolicy = lazy(() => import("./screens/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./screens/TermsOfUse"));
const Subscription = lazy(() => import("./screens/Subscription"));
const RentARobot = lazy(() => import("./screens/RentARobot"));
const Serveus911 = lazy(() => import("./screens/Serveus911"));
// const TrainARobot = lazy(() => import("./screens/TrainARobot"));
// const ConnectAndCollaborate = lazy(() => import("./screens/ConnectAndCollaborate"));
const MarketPlace = lazy(() => import("./screens/MarketPlace"));
const OwnerOperators = lazy(() => import("./screens/OwnerOperators"));
const About = lazy(() => import("./screens/About"));
const Support = lazy(() => import("./screens/Support"));

export default function App() {
  return (
    <>
      <div className="vh-100 ">
        <Router>
          <ScrollToTop />
          <TopNavbar />
          <DownloadApp />
          <Suspense fallback={<LoadingSpinner />}>
            <div className="white_smoke">
              <Routes>
                <Route path="/" element={<MapsPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/support" element={<Support />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/cards" element={<Subscription />} />
                <Route path="/rent-a-robot" element={<RentARobot />} />
                <Route path="/serveus911" element={<Serveus911 />} />
                {/* <Route path="/work-buddy" element={<TrainARobot />} /> */}
                {/* <Route
              path="/connect-and-collaborate"
              element={<ConnectAndCollaborate />}
              /> */}
                <Route
                  path="/marketplace"
                  element={<MarketPlace />}
                />
                <Route path="/owner-operators" element={<OwnerOperators />} />
                <Route path="/about" element={<About />} />
              </Routes>
            </div>
          </Suspense>
          <ScrollToTopButton />
          <Footer />
        </Router>
      </div>

    </>
  );
}
